import React from 'react';

const CatalogDxCellReport: React.FC<any> = (props) => {
  const isGroup = props.rowType === 'group';
  let value;
  try {
    if (typeof props.value == 'string') {
      value = JSON.parse(props.value);
    } else {
      value = props.value;
    }
  } catch (e) {}
  return value && Array.isArray(value) ? (
    <span>
      {isGroup && <span>{props.column.caption}: </span>}
      {value
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map((tag, index) => {
          return (
            <span key={`tag_${index}`} style={{ marginLeft: index ? '6px' : '0' }}>
              {tag.type == 'Rent' ? '(A)' : ''} {tag.title}
            </span>
          );
        })}
      {isGroup && <span> - {props.data.count}</span>}
    </span>
  ) : null;
};

export default CatalogDxCellReport;
