import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import AdaptiveHeaderTabs from '@components/fw/AdaptiveElements/AdaptiveHeaderTabs';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import AccessRoute from '@components/fw/RoutingContext/AccessRoute';
import PublishMessage from '@components/routed/Dashboard/PublishMessage/PublishMessage';

import routingStore from '@stores/fw/routingStore/routingStore';
import userStore from '@stores/userStore/userStore';

import AboutPage from './About/AboutPage';
import AdminBillingPage from './AdminBilling/AdminBillingPage';
import MonitorPage from './Monitor/MonitorPage';
import NsiPage from './Nsi/NsiPage';
import SourceLogsInput from './SourceLogs/SourceLogsInput';
import SourceLogsOutput from './SourceLogs/SourceLogsOutput';

const { TabPane } = Tabs;

class Dashboard extends Component<RouteComponentProps> {
  render() {
    const path = routingStore.location.pathname;
    const user = userStore.userData;
    const isAdminOrTech = user.roleCode === 'admin' || user.roleCode === 'tech';

    return (
      <>
        <AdaptivePageHeader
          subTitle={
            <AdaptiveHeaderTabs defaultActiveKey={path} onTabClick={(e) => this.props.history.push(e)}>
              {isAdminOrTech && <TabPane tab={'Мониторинг'} key="/dashboard/monitor"></TabPane>}
              {isAdminOrTech && <TabPane tab={'Входящие'} key="/dashboard/sourceInput"></TabPane>}
              {isAdminOrTech && <TabPane tab={'Исходящие'} key="/dashboard/sourceOutput"></TabPane>}
              {isAdminOrTech && <TabPane tab={'НСИ'} key="/dashboard/nsi"></TabPane>}
              {isAdminOrTech && <TabPane tab={'Биллинг'} key="/dashboard/adminBilling"></TabPane>}
              {user.roleCode === 'admin' && <TabPane tab={'Создать уведомление'} key="/dashboard/publishMessage"></TabPane>}
              <TabPane tab={'Список версий'} key="/dashboard/about"></TabPane>
            </AdaptiveHeaderTabs>
          }
          className={'page_header'}
        />
        <Switch>
          <AccessRoute path="/dashboard/monitor" component={MonitorPage} canAccess={isAdminOrTech} />
          <AccessRoute path="/dashboard/sourceInput" component={SourceLogsInput} canAccess={isAdminOrTech} />
          <AccessRoute path="/dashboard/sourceOutput" component={SourceLogsOutput} canAccess={isAdminOrTech} />
          <AccessRoute path="/dashboard/nsi" component={NsiPage} canAccess={isAdminOrTech} />
          <AccessRoute path="/dashboard/adminBilling" component={AdminBillingPage} canAccess={isAdminOrTech} />
          <AccessRoute path="/dashboard/about" component={AboutPage} canAccess={true} />
          <AccessRoute path="/dashboard/publishMessage" component={PublishMessage} canAccess={user.roleCode === 'admin'} />
        </Switch>
      </>
    );
  }
}

export default observer(Dashboard);
