import { Button, Divider, Form, Input, Spin } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import ScrolledContainer from '@components/fw/ScrolledContainer';

import messengerStoreService from '@stores/messengerStore/messengerStore.service';

import { showSuccessNotify } from '@utils/notify';

interface State {
  isBusy: boolean;
}

class PublishMessage extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isBusy: false,
    };
  }

  componentDidMount() {
    document.title = 'Рассылка';
  }

  onSend = (data) => {
    this.setState({ isBusy: true });
    messengerStoreService
      .publishMessage(data.text)
      .then((ans) => showSuccessNotify('Сообщение отправлено ' + ans + ' пользователям'))
      .finally(() => this.setState({ isBusy: false }));
  };

  render() {
    const isBusy = this.state.isBusy;

    return (
      <ScrolledContainer className={'scrolledContainer'} style={{ height: 'calc(100vh - var(--header-height)' }}>
        <Spin spinning={isBusy}>
          <AdaptiveCard>
            <Form layout={'vertical'} onFinish={this.onSend}>
              <Form.Item label={'Текст'} name={'text'}>
                <Input.TextArea />
              </Form.Item>
              <Divider />
              <Button type={'primary'} htmlType={'submit'}>
                Отправить
              </Button>
            </Form>
          </AdaptiveCard>
        </Spin>
      </ScrolledContainer>
    );
  }
}

export default PublishMessage;
