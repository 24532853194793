import { Col, Modal, Row, Spin } from 'antd';
import Search from 'antd/es/input/Search';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import XMLViewer from 'react-xml-viewer';

import { GLOBAL_DATETIME_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { FileInputModel } from '@stores/sourceLogsStore/sourceLogsData';
import sourceLogsStore from '@stores/sourceLogsStore/sourceLogsStore';

interface ContainerAddProps {
  fileId: string;
  onClose(): void;
}

interface ContainerAddState {
  data: FileInputModel | null;
  isBusy: boolean;
  searchString: string;
}

@observer
class SourceLogsContentViewer extends Component<ContainerAddProps, ContainerAddState> {
  constructor(props: ContainerAddProps) {
    super(props);

    this.state = {
      data: null,
      isBusy: true,
      searchString: '',
    };
  }

  filter(searchString: string) {
    const data = this.state.data;
    let content = JSON.parse(data.content);
    content['data'] = content['data'].filter(
      (x) =>
        (x['NOM_VAG'] && x['NOM_VAG'].toString().includes(searchString.toLowerCase())) ||
        (x['NOM_KONT'] && x['NOM_KONT'].toLowerCase().includes(searchString.toLowerCase())),
    );
    return content;
  }

  componentDidMount(): void {
    sourceLogsStore
      .getContent(this.props.fileId)
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => {
        this.setState({ isBusy: false });
      });
  }

  render() {
    const isBusy = this.state.isBusy;
    const searchString = this.state.searchString;
    const data = this.state.data;

    return (
      <>
        <Modal
          width={'50%'}
          okButtonProps={{ style: { display: 'none' } }}
          open={!!this.props.fileId}
          onOk={this.props.onClose}
          onCancel={this.props.onClose}>
          <Spin spinning={isBusy}>
            {data && (
              <div>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={5} style={{ fontSize: 'large', fontWeight: 'bold' }}>
                    Контент
                  </Col>
                  <Col span={18}>
                    <Search
                      size={'middle'}
                      placeholder="поиск"
                      onSearch={(value) => this.setState({ searchString: value })}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={5}>Название</Col>
                  <Col span={5}>Источник</Col> <Col span={5}>Дата</Col> <Col span={5}>Состояние</Col>
                  <Col span={4}>Tип</Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={5}>{data.name}</Col>
                  <Col span={5}>{data.sourceName}</Col>
                  <Col span={5}>{moment(data.dateTime).format(GLOBAL_DATETIME_FORMAT)}</Col>
                  <Col span={5}>{data.status}</Col>
                  <Col span={4}>{data.formatType}</Col>
                </Row>
                <pre>
                  {data.formatType === 'json' && (
                    <ReactJson
                      displayDataTypes={false}
                      displayObjectSize={false}
                      src={searchString ? this.filter(searchString) : JSON.parse(data.content)}
                    />
                  )}
                  {data.formatType === 'xml' && <XMLViewer xml={data.content} overflowBreak={true} />}
                </pre>
              </div>
            )}
          </Spin>
        </Modal>
      </>
    );
  }
}
export default SourceLogsContentViewer;
