import HttpClient from '@utils/httpClient';

import { ChatDialog, ChatMessage } from './messengerStoreData';

const service = {
  getDialog(dialogId: number) {
    return HttpClient.get(`/api/chat/dialog/${dialogId}`).then((resp) => {
      return resp.data;
    });
  },

  getDialogs(): Promise<ChatDialog[]> {
    return HttpClient.get(`/api/chat/dialog/list`).then((resp) => {
      return resp.data;
    });
  },

  addDialog(data: any) {
    return HttpClient.post(`/api/chat/dialog/add`, data).then((resp) => {
      return resp.data;
    });
  },

  updateDialog(data: any) {
    return HttpClient.post(`/api/chat/dialog/update`, data).then((resp) => {
      return resp.data;
    });
  },

  removeDialog(dialogId: number) {
    return HttpClient.delete(`/api/chat/dialog/remove/${dialogId}`).then((resp) => {
      return resp.data;
    });
  },

  getMessages(dialogId: number): Promise<ChatMessage[]> {
    return HttpClient.get(`/api/chat/message/list/${dialogId}`).then((resp) => {
      return resp.data;
    });
  },

  publishMessage(message: string): Promise<number> {
    return HttpClient.post(`/api/chat/publish`, { message }).then((resp) => {
      return resp.data;
    });
  },

  // attachment(messageId: number) {
  //   return HttpClient.get(`/api/chat/attachment/download/${messageId}`, {
  //     responseType: 'blob',
  //     timeout: 30000,
  //   }).then((resp) => {
  //     return resp.data;
  //   });
  // },

  attachment(messageId: number) {
    return HttpClient.get(`/api/chat/attachment/download/${messageId}`).then((resp) => {
      return resp.data;
    });
  },
};

export default service;
